import Vue from "vue";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("healthPath.create.title"),
            isEdit: false,
            customer: {},
            data: {},
            hasMovedToGym: false,
            isCompleted: false,
            validationOptions: {
                rules: {
                    name: {
                        required: true
                    },
                }
            }
        };
    },
    methods: {
        onIsCompletedChanged(value) {
            if (value)
                this.hasMovedToGym = false;
        },
        onHasMovedToGymChanged(value) {
            if (value)
                this.isCompleted = false;
        },
        async create() {
            this.checkDates();
            return await this.$store.dispatch(`customer/createHealthPath`, {
                customerId: this.$route.params.customerId,
                data: this.data
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("healthPath.create.toastSuccessTitle"), this.$t("healthPath.create.toastSuccessContent", {
                name: this.data.name
            }));
            this.backToGridPage();
        },
        async update() {
            this.checkDates();
            return await this.$store.dispatch(`customer/editHealthPath`, {
                customerId: this.$route.params.customerId,
                id: this.$route.params.id,
                data: this.data
            });
        },
        updateSuccess() {
            this.$toast.showSuccessToast(this.$t("healthPath.edit.toastSuccessTitle"), this.$t("healthPath.edit.toastSuccessContent", {
                name: this.data.name
            }));
            this.backToGridPage();
        },
        cancel() {
            this.backToGridPage();
        },
        backToGridPage() {
            if (this.$route.params.customerId != undefined)
                return this.$router.push(`/customers/${this.$route.params.customerId}/healthPath`);
            return this.$router.push('/customers');
        },
        checkDates() {
            if (!this.isCompleted) {
                this.data.completedAt = null;
                this.data.movedToGymAt = null;
            }
            if (!this.hasMovedToGym)
                this.data.movedToGymAt = null;
            if ((this.isCompleted || this.hasMovedToGym) && this.data.completedAt == null)
                this.data.completedAt = new Date();
            if (this.hasMovedToGym && this.data.movedToGymAt == null)
                this.data.movedToGymAt = new Date();
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.isEdit = this.$route.params.id != undefined;
            this.customer = await this.$store.dispatch(`customer/get`, {
                id: this.$route.params.customerId
            });
            if (this.isEdit) {
                this.data = await this.$store.dispatch(`customer/getHealthPath`, {
                    id: this.$route.params.id,
                    customerId: this.$route.params.customerId
                });
                this.title = this.$t("healthPath.edit.title");
                this.isCompleted = this.data.completedAt != null && this.data.movedToGymAt == null;
                this.hasMovedToGym = this.data.completedAt != null && this.data.movedToGymAt != null;
            }
            this.setTitle(`${this.title} - ${this.customer.fullName}`);
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
    }
});
